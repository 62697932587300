import Axios from "axios";
import { LOCAL_STORAGE_PRIMARY_COLOR, SITE_DEFAULT_PRIMARY_COLOR } from "../constants";
import { AboutUsConfiguration, ExcelDataType, UpsertAboutUsConfiguration } from "../models/misc";
import { PageId } from "../store/UIState";
import { authorizationHeader } from "./apiUtils";
import { DomainLayout} from "../store/domain"

const routes = {
  aboutUs: "/api/site/aboutus",
  maintenance: "/api/site/maintenance",
  maintenanceMessage: "/api/site/maintenance/message",
  pdf: "/api/site/pdfcheck",
  pdfDownload:"/api/site/pdfdownload",
  excel: "/api/site/exportExcel",
  lastUpdate: "/api/site/lastupdate",
  domainLayout: "/api/site/domainLayout",
  sendInvitation: "/api/site/sendInvitation",
  vendors: "/api/diamonds/vendors",
  addVendor: "/api/site/addVendor",
  deleteVendor: "/api/site/deleteVendor",
  siteVendors: "/api/site/siteVendors"
};

const getAboutUsConfiguration = async (): Promise<AboutUsConfiguration> => {
  const result = await Axios.get<AboutUsConfiguration>(routes.aboutUs);
  return result.data;
};
const getLastCsvUpdateTime = async (): Promise<string> => {
  const result = await Axios.get<string>(routes.lastUpdate);
  return result.data;
};

const getMaintenance = async (): Promise<boolean> => {
  const result = await Axios.get<boolean>(routes.maintenance);
  return result.data;
};

const setMaintenance = async (isEnabled: boolean): Promise<void> => {
  await Axios.put(`${routes.maintenance}?isenabled=${isEnabled}`, null, {
    headers: authorizationHeader(),
  });
};

const setMaintenanceMessage = async (message: string): Promise<void> => {
  await Axios.put(
    routes.maintenanceMessage,
    { message },
    {
      headers: authorizationHeader(),
    }
  );
};



const getMaintenanceMessage = async (): Promise<string> => {
  const result = await Axios.get<string>(routes.maintenanceMessage);
  return result.data;
};

const updateAboutUsConfiguration = async (configuration: UpsertAboutUsConfiguration): Promise<AboutUsConfiguration> => {
  const result = await Axios.patch<AboutUsConfiguration>(routes.aboutUs, configuration, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const setSiteMainColor = (setDefaultColor?: boolean): void => {
  if (setDefaultColor) {
    document.documentElement.style.cssText = `--bright-color: ${SITE_DEFAULT_PRIMARY_COLOR}`;
    return;
  }

  const color = localStorage.getItem(LOCAL_STORAGE_PRIMARY_COLOR);
  if (!color) {
    return;
  }
  document.documentElement.style.cssText = `--bright-color: ${color}`;
};

const checkPdfStatus = async (url: string): Promise<number> => {
  const response = await Axios.post(routes.pdf, { url }, { headers: { "Content-Type": "application/json" } });
  return response.data as number;
};
const exportExcel = async (data: ExcelDataType[], totalPrice: number, totalCarats: number, pageId: PageId): Promise<void> => {
  await Axios.post(routes.excel, { data, totalCarats, totalPrice, pageId } , {
    headers: authorizationHeader(),
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'AlonAvinoam.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
});
};
const pdfDownload = async (url: string, diamondId: string): Promise<void> => {
  await Axios.post(routes.pdfDownload, {url } , {
    headers: authorizationHeader(),
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${diamondId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
});
};

const getDomainLayout = async (): Promise<DomainLayout> =>{
  const response = await Axios.get<DomainLayout>(routes.domainLayout)
  return response.data
}

const sendInvitation = async  (url: string): Promise<void> =>{
  await Axios.post(routes.sendInvitation, { url }, { headers: authorizationHeader() })
}

const getVendors = async (): Promise<string[]> => {
  const response = await Axios.get<string[]>(routes.vendors,{
    headers: authorizationHeader(),
  }
  );
  return response.data;
}

const getSiteVendors = async (): Promise<string[]> => {
  const response = await Axios.get<string[]>(routes.siteVendors, {
    headers: authorizationHeader(),
  });
  return response.data;
};

const addVendor = async (vendor: string): Promise<void> => {
  await Axios.post(
    routes.addVendor,
    { vendor }, 
    {
      headers: {
        ...authorizationHeader(),
        'Content-Type': 'application/json', 
      },
    }
  );
};
const deleteVendor = async (vendor: string): Promise<void> => {
  await Axios.post(
    routes.deleteVendor,
    { vendor }, 
    {
      headers: {
        ...authorizationHeader(),
        'Content-Type': 'application/json', 
      },
    }
  );
};


export {
  getAboutUsConfiguration,
  getMaintenance,
  getMaintenanceMessage,
  setMaintenance,
  setMaintenanceMessage,
  updateAboutUsConfiguration,
  setSiteMainColor,
  checkPdfStatus,
  exportExcel,
  getLastCsvUpdateTime,
  pdfDownload,
  getDomainLayout,
  sendInvitation,
  getVendors,
  addVendor,
  deleteVendor,
  getSiteVendors,
};
