import React, { lazy, Suspense, useEffect, useState } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Loading from "./components/loading";
import PrivateRoute from "./components/private-route";
import PublicRoute from "./components/public-route";
import history from "./history";
import * as auth from "./store/auth";
import * as domain from "./store/domain";
import { actions as dataActions } from "./store/data";
import { actions as pushNotificationsActions } from "../src/store/pushNotifications";

import { ApplicationState } from "./store/store";
import ReactGa from "react-ga";
import If from "./components/if";
import { Alert } from "reactstrap";
import IdleTimerComponent from "./components/idleTimer";

const layout = lazy(async () => import("./components/layout"));

const aboutUs = lazy(() => import("./pages/about-us"));
const account = lazy(() => import("./pages/account/account"));
const admin = lazy(() => import("./pages/admin/admin-home"));
const cart = lazy(() => import("./pages/cart"));
const comingSoon = lazy(() => import("./pages/coming-soon"));
const blackDiamonds = lazy(() => import("./pages/category/black-diamonds"));
const changePassword = lazy(() => import("./pages/change-password"));
const confirmEmail = lazy(() => import("./pages/confirm-email"));
const contact = lazy(() => import("./pages/contact"));
const fancyDiamonds = lazy(() => import("./pages/category/fancy-diamonds"));
const SaltAndPepperDiamonds = lazy(() => import("./pages/category/salt-pepper"));
const fancyShapeLines = lazy(() => import("./pages/category/fancy-shape-lines"));
const home = lazy(() => import("./pages/home"));
const login = lazy(() => import("./pages/login/login"));
const maintenance = lazy(() => import("./pages/maintenance"));
const matchingPairs = lazy(() => import("./pages/category/matching-pairs"));
const meleeBySize = lazy(() => import("./pages/melee-by-size"));
const registration = lazy(() => import("./pages/registration"));
const resetPassword = lazy(() => import("./pages/reset-password"));
const shows = lazy(() => import("./pages/shows"));
const singleItem = lazy(() => import("./pages/category/single-item"));
const welcome = lazy(() => import("./pages/welcome"));
const whiteDiamonds = lazy(() => import("./pages/category/white-diamonds"));
const labGrownDiamonds = lazy(() => import("./pages/category/lab-grown-diamonds"));
const nameDiamonds = lazy(() => import("./pages/nyd"));

const Router = (props: TProps) => {
  const { fetchIsMaintenance, initUserState, fetchDomainLayout, user, retrieved } = props;
  const router = useHistory();

  const [loading, setLoading] = useState(true);

  const [domainLayoutLoaded, setDomainLayoutLoaded] = useState(false);

  const loadDomainLayout = async () => {
    await fetchDomainLayout();
    setDomainLayoutLoaded(true);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      await fetchIsMaintenance(); // Fetch maintenance state
      await initUserState(); // Initialize user state
      await loadDomainLayout(); // Fetch domain layout
    };
    
    fetchData().then(() => setLoading(false));
  }, [fetchIsMaintenance, initUserState, loadDomainLayout]);
  

  useEffect(() => {
    ReactGa.pageview(window.location.pathname + window.location.search);
    fetchIsMaintenance();
    initUserState();
  }, [fetchIsMaintenance, initUserState, router.location.pathname]);
  useEffect(() => {
    fetchDomainLayout();
  }, [fetchDomainLayout]);



  useEffect(() => {
    setLoading(!retrieved); 
  }, [retrieved]);


  if (loading || !retrieved || !domainLayoutLoaded) { 
    return <Loading />;
  }
  

  return props.retrieved ? (
    <Suspense fallback={<Loading />}>
      <IdleTimerComponent></IdleTimerComponent>
      <PublicRoute exact path="/" component={home} layout={layout} pageTitle={"Home"} />
      <PublicRoute path="/about-us" component={aboutUs} layout={layout} withLogos={true} pageTitle={"About Us"} />
      <PublicRoute path="/change-password" component={changePassword} layout={layout} pageTitle={"Change Password"} />
      <PublicRoute path="/confirm-email" component={confirmEmail} layout={layout} pageTitle={"Confirm Email"} />
      <PublicRoute path="/contact" component={contact} layout={layout} pageTitle={"Contact"} />
      <PublicRoute path="/login" component={login} layout={layout} pageTitle={"Login"} />
      <PublicRoute path="/maintenance" component={maintenance} pageTitle={"Maintenance"} />
      <PublicRoute path="/register" component={registration} layout={layout} pageTitle={"Register"} />
      <PublicRoute path="/reset-password" component={resetPassword} layout={layout} pageTitle={"Reset Password"} />
      <PublicRoute path="/shows" component={shows} layout={layout} pageTitle={"Shows"} />
      <PublicRoute path="/welcome" component={welcome} layout={layout} pageTitle={"Welcome"} />
      <PublicRoute path="/nyd" component={nameDiamonds} layout={layout} pageTitle={"Name Your Diamond"} />
      
      {user?.accesses[0]?.fancyShape ? (
        <PrivateRoute
          path="/fancy-shape-lines"
          component={fancyShapeLines}
          layout={layout}
          pageTitle={"Fancy Shape Lines"}
          roles={["Access"]}
        />
      ) : (
        <PublicRoute
          path="/fancy-shape-lines"
          component={fancyShapeLines}
          layout={layout}
          pageTitle={"Fancy Shape Lines"}
        />
      )}
      {user?.accesses[0]?.black ? (
        <PrivateRoute
          path="/black-diamonds"
          roles={["Access"]}
          component={blackDiamonds}
          layout={layout}
          pageTitle={"Black Diamonds"}
        />
      ) : (
        <PublicRoute path="/black-diamonds" component={blackDiamonds} layout={layout} pageTitle={"Black Diamonds"} />
      )}
      {user?.accesses[0]?.snP ? (
        <PrivateRoute
          path="/salt&pepper"
          component={SaltAndPepperDiamonds}
          roles={["Access"]}
          layout={layout}
          pageTitle={"Salt&Pepper"}
        />
      ) : (
        <PublicRoute path="/salt&pepper" component={SaltAndPepperDiamonds} layout={layout} pageTitle={"Salt&Pepper"} />
      )}
      {user?.accesses[0]?.mathingPairs ? (
        <PrivateRoute
          path="/matching-pairs"
          component={matchingPairs}
          roles={["Access"]}
          layout={layout}
          pageTitle={"Matching Pairs"}
        />
      ) : (
        <PublicRoute path="/matching-pairs" component={matchingPairs} layout={layout} pageTitle={"Matching Pairs"} />
      )}
      {user?.accesses[0]?.melee ? (
        <PrivateRoute
          path="/melee-by-size"
          component={meleeBySize}
          roles={["Access"]}
          layout={layout}
          pageTitle={"Melee By Size"}
        />
      ) : (
        <PublicRoute path="/melee-by-size" component={meleeBySize} layout={layout} pageTitle={"Melee By Size"} />
      )}
      <PrivateRoute path="/build-your-eternity" component={comingSoon} layout={layout} pageTitle={"Build Eternity"} />
      {user?.role === "Access" ? (
        <PublicRoute path="/cart" component={cart} layout={layout} pageTitle={"Cart"} />
      ) : (
        <PrivateRoute path="/cart" component={cart} layout={layout} pageTitle={"Cart"} />
      )}

      {user?.accesses[0]?.fancyColor ? (
        <PrivateRoute
          path="/fancy-diamonds"
          component={fancyDiamonds}
          roles={["Access"]}
          layout={layout}
          pageTitle={"Fancy Diamonds"}
        />
      ) : (
        <PublicRoute path="/fancy-diamonds" component={fancyDiamonds} layout={layout} pageTitle={"Fancy Diamonds"} />
      )}
      {user?.accesses[0]?.white ? (
        <PrivateRoute
          path="/white-diamonds"
          component={whiteDiamonds}
          roles={["Access"]}
          layout={layout}
          pageTitle={"White Diamonds"}
        />
      ) : (
        <PublicRoute path="/white-diamonds" component={whiteDiamonds} layout={layout} pageTitle={"White Diamonds"} />
      )}
      {!user || user?.accesses[0]?.labGrown || (user?.accesses?.length === 0 && user?.role !== "Administrator") ? (
        <PrivateRoute
          path="/lab-grown"
          component={labGrownDiamonds}
          roles={["Access"]}
          layout={layout}
          pageTitle={"Lab Grown Diamonds"}
        />
      ) : (
        <PublicRoute path="/lab-grown" component={labGrownDiamonds} layout={layout} pageTitle={"Lab Grown Diamonds"} />
      )}

      <PrivateRoute path="/diamond/:stockId" component={singleItem} layout={layout} pageTitle={"Details"} />
      {user?.role === "Access" ? (
        <PublicRoute path="/account" component={account} layout={layout} pageTitle={"Account"} />
      ) : (
        <PrivateRoute path="/account" component={account} layout={layout} pageTitle={"Account"} />
      )}
      <PrivateRoute path="/admin" component={admin} roles={["Administrator"]} pageTitle={"Admin"} />
      {props.isMaintenance && !history.location.pathname.startsWith("/admin") && <Redirect to="/maintenance" />}
    </Suspense>
  ) : null;
};

const connector = connect(
  ({ auth: authDate, data }: ApplicationState) => ({
    retrieved: authDate.retrieved,
    isMaintenance: data.maintenance.isEnabled,
    user: authDate?.user,
  }),
  {
    fetchIsMaintenance: dataActions.fetchMaintenanceState,
    initUserState: auth.actions.initUserState,
    fetchDomainLayout: domain.actions.fetchDomainLayout,
    ...pushNotificationsActions,
  }
);

type TProps = ConnectedProps<typeof connector> & {
  retrieved: boolean;
};

export default connector(Router);
